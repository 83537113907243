import Information from './Information'

const Term = (): JSX.Element =>
  Information(`
# 利用規約
この利用規約（以下，「本規約」といいます。）は，このウェブサイト上で提供するサービス「MX Track」（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

## 第1条（適用）
本規約は，ユーザーと本サービス運営との間の本サービスの利用に関わる一切の関係に適用されるものとします。

## 第2条（利用登録）
1. 登録希望者が本サービス運営の定める方法によって利用登録を申請し，本サービス運営がこれを承認することによって，利用登録が完了するものとします。

2. 本サービス運営は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。

  1. 利用登録の申請に際して虚偽の事項を届け出た場合
  2. 本規約に違反したことがある者からの申請である場合
  3. その他，本サービス運営が利用登録を相当でないと判断した場合

## 第3条（ユーザーIDおよびパスワードの管理）
1. ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを管理するものとします。
2. ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。本サービス運営は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。

## 第4条（禁止事項）
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

1. 法令または公序良俗に違反する行為
2. 犯罪行為に関連する行為
3. 本サービスのサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
4. 本サービスの運営を妨害するおそれのある行為
5. 他のユーザーに関する個人情報等を収集または蓄積する行為
6. 他のユーザーに成りすます行為
7. 本サービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
8. 本サービスの他の利用者または第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
9. その他，本サービス運営が不適切と判断する行為

## 第5条（本サービスの提供の停止等）
1. 本サービス運営は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。

  1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
  2. 地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
  3. コンピュータまたは通信回線等が事故により停止した場合
  4. その他，本サービスの提供が困難と判断した場合

2. 本サービス運営は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。

## 第6条（著作権）
1. ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報のみ，本サービスを利用し，投稿または編集することができるものとします。
2. ユーザーが本サービスを利用して投稿または編集した文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，本サービス運営は，本サービスを利用して投稿または編集された文章，画像，映像等を利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
3. 前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他知的財産権はすべて本サービス運営または本サービス運営にその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。

## 第7条（利用制限および登録抹消）
1. 本サービス運営は，以下の場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。

  1. 本規約のいずれかの条項に違反した場合
  2. 登録事項に虚偽の事実があることが判明した場合
  3. 破産，民事再生，会社更生または特別清算の手続開始決定等の申立がなされたとき
  4. 1年間以上本サービスの利用がない場合
  5. 本サービス運営からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
  6. 第2条第2項各号に該当する場合
  7. その他，本サービス運営が本サービスの利用を適当でないと判断した場合

2. 前項各号のいずれかに該当した場合，ユーザーは，当然に本サービス運営に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
3. 本サービス運営は，本条に基づき本サービス運営が行った行為によりユーザーに生じた損害について，一切の責任を負いません。

## 第8条（保証の否認および免責事項）
1.  本サービス運営は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
2. 本サービス運営は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する本サービス運営とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
3. 前項ただし書に定める場合であっても，本サービス運営は，本サービス運営の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本サービス運営またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，本サービス運営の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
4. 本サービス運営は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。

## 第9条（サービス内容の変更等）
本サービス運営は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。

## 第10条（利用規約の変更）

本サービス運営は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。

## 第11条（通知または連絡）
ユーザーと本サービス運営との間の通知または連絡は，本サービス運営の定める方法によって行うものとします。

## 第12条（権利義務の譲渡の禁止）
ユーザーは，本サービス運営の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。

## 第13条（準拠法・裁判管轄）
1. 本規約の解釈にあたっては，日本法を準拠法とします。
2. 本サービスに関して紛争が生じた場合には，本サービス運営の本店所在地を管轄する裁判所を専属的合意管轄とします。

以上
`)

export default Term
